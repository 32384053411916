/**
 * @generated SignedSource<<027652fc67396e9e6e33ff88aa488737>>
 * @relayHash 6af8fbebb9ea9f3fde12dcc53fd4a2f0
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/488.0.0-2024-11-12T19:24:21.397Z/useFetchViewerQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type useFetchViewerQuery$variables = {
  countryCode?: string | null;
  parsePhoneNumber: boolean;
  phoneNumber: string;
};
export type useFetchViewerQuery$data = {
  readonly viewer: {
    readonly geography: {
      readonly countriesCallingCodes: ReadonlyArray<{
        readonly abbreviation: string | null;
        readonly countryCallingCode: string | null;
        readonly name: string | null;
      } | null> | null;
    } | null;
    readonly parsedPhoneNumber?: {
      readonly countryCallingCode: string | null;
      readonly countryCode: string | null;
      readonly internationalNumber: string | null;
      readonly isValid: boolean | null;
      readonly number: string | null;
    } | null;
  };
};
export type useFetchViewerQuery = {
  response: useFetchViewerQuery$data;
  variables: useFetchViewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parsePhoneNumber"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneNumber"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCallingCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Geography",
  "kind": "LinkedField",
  "name": "geography",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CallingCodeObject",
      "kind": "LinkedField",
      "name": "countriesCallingCodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "abbreviation",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "condition": "parsePhoneNumber",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "countryCode",
          "variableName": "countryCode"
        },
        {
          "kind": "Variable",
          "name": "phoneNumber",
          "variableName": "phoneNumber"
        }
      ],
      "concreteType": "ParsedPhoneNumberType",
      "kind": "LinkedField",
      "name": "parsedPhoneNumber",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isValid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internationalNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useFetchViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useFetchViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/488.0.0-2024-11-12T19:24:21.397Z/useFetchViewerQuery",
    "metadata": {},
    "name": "useFetchViewerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "8da26b76037f48a7276b140de7c6fe20";

export default node;
